import { buildCdnLink } from './build-cdn-link'

const gameAssetUrls = [
  buildCdnLink('/assets/gates.glb'),
  buildCdnLink('/assets/etch.glb'),
  buildCdnLink('/assets/field.glb'),
  buildCdnLink('/assets/goalkeeper.glb'),
]

export const fetchGameAssets = () => {
  gameAssetUrls.forEach(url => {
    fetch(url)
  })
}
