import Alpine from 'alpinejs'
import flagsmith from 'flagsmith'
import Swiper from 'swiper'
import { Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'

import { fetchGameAssets } from 'utils/fetch-game-assets'
import { initFlagsmith } from 'shared/feature-flags'
import { analyticEvent } from 'analytic'
import { fetchAvailability } from 'api'
import { validateGeolocation } from 'geolocation'

import './style.scss'

const appHeight = () => {
  const doc = document.documentElement
  doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}
window.addEventListener('resize', appHeight)
appHeight()

type AppStore = {
  init: () => Promise<void>
  popup: 'rules' | 'no-matches' | 'wrong-location' | 'camera-not-available'
  nearestGame: {
    name: string
    date: string
    time: string
  }
  fetchAvailability: () => Promise<void>
}

Alpine.store('app', {
  async init() {
    fetchGameAssets()
    await initFlagsmith()
  },
  popup: 'rules',
  nearestGame: {
    name: '',
    date: '',
    time: '',
  },
  async fetchAvailability() {
    const { current, nearest } = await fetchAvailability()

    if (current === null) {
      this.nearestGame = nearest
      this.popup = 'no-matches'
      analyticEvent('no-matches-popup-shown')

      return
    }

    if (flagsmith.hasFeature('geolocation-restriction')) {
      const isLocationValid = await validateGeolocation()

      if (!isLocationValid) {
        this.popup = 'wrong-location'
        analyticEvent('wrong-location-popup-shown')

        return
      }
    }

    location.href = './ar.html'
  },
} satisfies AppStore)

Alpine.start()

const swiper = new Swiper('.swiper', {
  loop: true,
  speed: 400,
  slidesPerView: 1,
  spaceBetween: 0,
  modules: [Pagination],
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true,
  },
})
